import React from 'react'

const Bio=()=> {
  return (
    <div className="bio">
      <p>Tibby Xu is a web developer and designer<br />currently based in New York City and Vancouver.</p>
    </div>
  )
}

export default Bio;
